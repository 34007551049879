<template>
  <transition
    name="benefits"
  >
    <div class="container d-flex flex-column align-content-center align-items-center mx-auto mt-5">
      <p class="text-center display-5">Преимущества</p>
      <div class="d-flex flex-column flex-md-row align-items-center mt-3">
        <div class="col-10 col-md-6 ">
          <img src="../assets/young-woman.jpg" alt="" class="img-fluid rounded">
        </div>
        <div class="col-10 col-md-6 mt-3 p-md-4">
          <p class="h5">Быстрый выезд 24/7</p>
          <p>Осуществляем выезд на место происшествия 24 часа в сутки сразу после вашего звонка!</p>
          <p class="h5">Объективная предварительная оценка ДТП</p>
          <p>Точно оцениваем ущерб и возможный размер страховой выплаты!</p>
          <p class="h5">Правильно фиксируем событие</p>
          <p>Производим цифровую фотосъемку места происшествия, повреждений и документов</p>
        </div>
      </div>

      <div class="d-flex flex-column flex-md-row align-items-center mt-3">
        <div class="col-10 col-md-6">
          <img src="../assets/car-with-problems.jpg" alt="" class="img-fluid rounded">
        </div>
        <div class="col-10 col-md-6 mt-3 p-md-4">
          <p class="h5">Оформляем документы за вас</p>
          <p>Юридически грамотное составление и оформление документов, сбор справок ГИБДД для страховой компании</p>
          <p class="h5">Общаемся с сотрудниками ГИБДД</p>
          <p>Взаимодействуем с сотрудниками ГИБДД в спорных случаях</p>
          <p class="h5">Поможем во всем</p>
          <p>Вызовем эвакуатор, техпомощь, окажем психологическую помощь и т.д.</p>

        </div>
      </div>
    </div>
  </transition>

</template>

<script>
export default {

}
</script>

<style scoped>

</style>