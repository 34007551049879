<template>
  <div class="footer mt-auto py-3 footer-bg">
    <div class="container d-flex flex-column align-items-center">
      <div class="footer__logo">
        <img class="d-block w-100" src="../assets/icons/logo.png" alt="">
      </div>
      <div class="h6 text-center mt-2">
        <p class="fw-bold m-0">Телефон</p>
        <a class="fw-light text-decoration-none" href="tel:+73432077838">+7 (343) 207-78-38</a>
      </div>
      <div class="h6 text-center m-0 mt-2">
        <p class="fw-bold m-0">E-mail</p>
        <a class="fw-light text-decoration-none" href="mailto:info@avangard196.ru">info@avangard196.ru</a>
      </div>
      <div class="h6 text-center m-0 mt-2">
        <p class="fw-bold m-0">Адрес</p>
        <p class="fw-light m-0">Блюхера 58, офис 401</p>
        <p class="fw-light m-0">Титова 27/3, офис 102</p>
        <p>Екатеринбург</p>
      </div>
      <div class="h6 text-center m-0 mt-2">
        <p class="fw-bold m-0">Режим работы</p>
        <p class="fw-light m-0">09:00 до 18:00</p>
      </div>
      <hr class="border-1 w-100 mt-3">
      <div class="mt-1">
        <div class="footer__logo mx-auto">
          <img class="d-block w-100" src="../assets/icons/logo.png" alt="">
        </div>
        <div class="h6 text-center mt-1">
          <p>© «Авангард» - Сервис онлайн страхования на Урале, 2010-2022.</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {

}
</script>

<style scoped>
.footer__logo {
  width: 200px;
  height: 45px;
}
.footer-bg {
  background: rgba(130,130,130,0.3);
}
</style>