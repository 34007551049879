<template>
  <div class="d-flex flex-column align-content-center align-items-center mx-auto mt-5 p-2">
    <p class="text-center display-5">Как мы помогаем при ДТП</p>
    <p class="text-center h6 mt-3">Принцип работы аварийных комиссаров компании Авангард196</p>
    <div class="d-flex flex-column flex-md-row justify-content-md-between mt-5">
      <div class="d-flex flex-column align-items-center col-md-3">
        <div class="d-block">
          <img src="../assets/icons/24-hours.png" alt="" class="img-width">
        </div>
        <p class="fw-bold">Вызываете специалиста</p>
        <p class="w-75 text-center">Круглосуточно принимаем заявки в любой район Екатеринбурга</p>
      </div>


      <div class="d-flex flex-column align-items-center col-md-3">
        <div class="d-block">
          <img src="../assets/icons/komissar.png" alt="" class="img-width">
        </div>
        <p class="fw-bold">Комиссар выезжает к вам</p>
        <p class="w-75 text-center">Выезжаем на место ДТП в течение 15 минут!</p>
      </div>

      <div class="d-flex flex-column align-items-center col-md-3">
        <div class="d-block">
          <img src="../assets/icons/quest.png" alt="" class="img-width">
        </div>
        <p class="fw-bold">Оформляем ДТП</p>
        <p class="w-75 text-center">Фиксируем повреждения <br>
          Определяем виновника<br>
          Составляем схему ДТП<br>
          Помогаем с европротоколом<br>
          При необходимости сопровождаем в ГАИ</p>
      </div>

      <div class="d-flex flex-column align-items-center col-md-3">
        <div class="d-block">
          <img src="../assets/icons/document.png" alt="" class="img-width">
        </div>
        <p class="fw-bold">Оформление заявки</p>
        <p class="w-75 text-center">Направляемся в офис для заполнения документов на оформление страхового случая</p>
      </div>
    </div>
  </div>
</template>

<script>
export default {

}
</script>

<style scoped>
.img-width {
  width: 100px;
  height: 80px;
}
</style>